<template>
  <div v-if="vacancies" class="career-vacancy__list-wrapper">
    <div v-if="vacancies.length" class="career-vacancy__list-titles">
      <div class="career-vacancy__list-title">{{ $t("position") }}:</div>
      <!-- <div class="career-vacancy__list-title">Город:</div> -->
      <div class="career-vacancy__list-title">{{ $t("experience") }}:</div>
    </div>
    <ul v-if="vacancies.length" class="career-vacancy__list">
      <locale-router-link
        :to="`vacancy/${item.id}`"
        v-for="(item, index) in vacancies"
        :key="index"
        class="career-vacancy__list-item"
      >
        <div v-if="getConditions(item)" class="career-vacancy__position">
          {{ getConditions(item) }}
        </div>
        <!-- <div
          v-if="item.attributes.required_experience"
          class="career-vacancy__city"
        >
          {{ item.attributes.required_experience }}
        </div> -->
        <div
          v-if="item.attributes.required_experience"
          class="career-vacancy__experience"
        >
          {{ item.attributes.required_experience }}
        </div>
      </locale-router-link>
    </ul>

    <p v-if="!vacancies.length" class="career-vacancy__none">
      {{ $t("VacanciesNone") }}
    </p>
    <div v-if="total > 6" class="career-vacancy__list-nav">
      <button
        class="career-vacancy__list-prev career-vacancy__list-arrow"
        @click="prevPaginate"
      >
        <svg
          width="62"
          height="62"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
          <path
            d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
            fill="#B5B5B5"
          />
        </svg>
      </button>
      <div class="career-vacancy__list-counter">
        <div class="career-vacancy__list-current">{{ pagination.page }}</div>
        /
        <div v-if="pageCount" class="career-vacancy__list-all">
          {{ pageCount }}
        </div>
      </div>
      <button
        class="career-vacancy__list-next career-vacancy__list-arrow"
        @click="nextPaginate"
      >
        <svg
          width="62"
          height="62"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
          <path
            d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
            fill="#B5B5B5"
          />
        </svg>
      </button>
    </div>
    <button
      v-if="!pagination.isLastPage && total > 6"
      class="career-vacancy__list-more"
      @click="nextVacancy"
    >
      {{ $t("seeAll") }}
    </button>
  </div>
  <div v-else class="loader-mini__container">
    <div class="loader-mini"></div>
  </div>
  <!-- Content -->
</template>

<script>
import { mapState } from "vuex";
import { getFilteredVacancies } from "@/api/vacancy";
export default {
  name: "VacancyList",
  props: {
    filters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vacancies: null,
      pageCount: null,
      total: null,
      pagination: {
        page: 1,
        pageSize: 6,
        isLastPage: false,
      },
    };
  },
  created() {
    getFilteredVacancies(
      { jobType: null, position: null },
      this.pagination
    ).then((vacancies) => {
      this.vacancies = vacancies.data;
      this.pageCount = vacancies.meta.pagination.pageCount;
      this.total = vacancies.meta.pagination.total;
    });
  },
  mounted() {},
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    ...mapState("vacancyModule", ["filter"]),
  },
  watch: {
    filters: {
      handler(filters) {
        this.pagination.page = 1;
        getFilteredVacancies(filters, this.pagination).then((vacancies) => {
          this.vacancies = vacancies.data;
          this.pageCount = vacancies.meta.pagination.pageCount;
          this.total = vacancies.meta.pagination.total;
        });
      },
    },
  },
  methods: {
    prevPaginate() {
      if (this.pagination.page !== 1) {
        this.pagination.page--;
        getFilteredVacancies(this.filters, this.pagination).then(
          (vacancies) => {
            this.vacancies = vacancies.data;
            this.total = vacancies.meta.pagination.total;
          }
        );
      }
    },
    nextPaginate() {
      if (this.pagination.page !== this.pageCount) {
        this.pagination.page++;
        getFilteredVacancies(this.filters, this.pagination).then(
          (vacancies) => {
            this.vacancies = vacancies.data;
            this.total = vacancies.meta.pagination.total;
          }
        );
      }
    },
    getCity(item) {
      return item.attributes?.cities?.data[0]?.attributes?.name;
    },
    getConditions(item) {
      return item.attributes?.conditions;
    },
    nextVacancy() {
      this.pagination.page++;
      getFilteredVacancies(this.filters, this.pagination).then((vacancies) => {
        this.vacancies = [...this.vacancies, ...vacancies.data];
        if (this.pagination.page === vacancies.meta.pagination.pageCount) {
          this.pagination.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
